<template>
  <div>
    <v-navigation-drawer
      app
      color="#f4f4f7"
      permanent
      :mini-variant="$vuetify.breakpoint.smAndDown"
      width="224"
      mini-variant-width="106"
    >
      <template #prepend>
        <div class="logo-container">
          <v-img
            :src="require('@/assets/svg/logo.svg')"
            contain
            max-width="75"
            class="logo align-center"
          />
          <h5 class="logo-title" v-show="!$vuetify.breakpoint.smAndDown">
            Облачное файловое хранилище
          </h5>
        </div>
      </template>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group color="secondary">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            class="menu-item"
          >
            <v-list-item-icon class="mr-3">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template #append>
        <div
          v-show="!$vuetify.breakpoint.smAndDown"
          style="cursor: pointer"
          @click="onUserClick"
        >
          <v-divider class="mb-2"></v-divider>
          <div class="text-subtitle-1">
            {{ username }}
          </div>
          <div class="text-subtitle-2">
            {{ email }}
          </div>
        </div>
        <v-divider class="mt-2"></v-divider>
        <div>
          <v-list dense>
            <v-list-item-group color="primary" @change="onLogoutClicked">
              <v-list-item class="menu-item">
                <v-list-item-icon class="mr-3">
                  <v-icon v-text="'mdi-exit-to-app'"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="'Выход'"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <transition name="fade">
          <router-view></router-view>
        </transition>
        <custom-snackbar ref="snackbar" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import "@/assets/styles/layouts.css";
import Snackbar from "@/components/SnackBar.vue";
import DialogService from "@/services/dialog";
import ErrorsMixins from "@/mixins/errors";

import AuthServiceADFS from "@/services/authADFS";

import EventBus from "@/main";

import store from "@/store/index";

export default {
  mixins: [ErrorsMixins],

  computed: {
    username() {
      return store.getters.username;
    },
    email() {
      return store.getters.email;
    },
  },

  mounted() {
    this.updateTokenRequest();
  },

  data() {
    return {
      items: [
        { title: "Контейнеры", icon: "mdi-safe-square-outline", to: "/" },
        { title: "Теги", icon: "mdi-tag-text-outline", to: "/Tags" },
        { title: "Файлы", icon: "mdi-file-multiple-outline", to: "/Files" },
        {
          title: "Пользователи",
          icon: "mdi-account-multiple-outline",
          to: { name: "Users" },
        }, // Fix for programmatically navigation in onUserClick()
        { title: "Журнал", icon: "mdi-notebook-outline", to: "/Journal" },
        { title: "Настройки", icon: "mdi-cogs", to: "/Settings" },
        { title: "Фоновые операции", icon: "mdi-timer-sand", to: "/Tasks" },
        {
          title: "Документация",
          icon: "mdi-help-circle-outline",
          to: "/Documentation",
        },
      ],
    };
  },

  methods: {
    updateTokenRequest() {
      if (new Date(this.$store.getters.timer) < new Date()) {
        DialogService.showConfirm(
          "Требуется повторная авторизация в ADFS. Авторизоваться повторно?"
        ).then(() => {
          AuthServiceADFS.clearData().then(() => {
            AuthServiceADFS.login();
          });
        });
      }
    },

    onLogoutClicked() {
      AuthServiceADFS.logout();
    },

    onUserClick() {
      if (this.$route.name != "Users")
        this.$router.push({ name: "Users", params: { currentUser: true } });
      else if (EventBus) EventBus.$emit("USER_CLICKED");
    },
  },

  components: {
    CustomSnackbar: Snackbar,
  },
};
</script>
